import React from 'react';
import WebSocketImageProcessor from './WebSocketImageProcessor';

const App = () => {
  return (
    <div>
      <WebSocketImageProcessor />
    </div>
  );
};

export default App;
