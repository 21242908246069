import React, { useState, useRef, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import './WebSocketVideoProcessor.css';

const WebSocketVideoProcessor = () => {
  const [lipColor, setLipColor] = useState('#ff0000');
  const [eyeColor, setEyeColor] = useState('#0000ff');
  const [processedImage, setProcessedImage] = useState(null);
  const ws = useRef(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const lipColorRef = useRef(lipColor);
  const eyeColorRef = useRef(eyeColor);

  useEffect(() => {
    handleWebSocketConnect();
    startCamera();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      stopCamera();
    };
  }, []);

  const handleWebSocketConnect = () => {
    ws.current = new WebSocket('wss://api.makeup.dkingsolution.org/ws');
    ws.current.onopen = () => {
      console.log('WebSocket connected');
    };
    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.image) {
        setProcessedImage(`data:image/jpeg;base64,${data.image}`);
      }
    };
    ws.current.onclose = () => {
      console.log('WebSocket disconnected');
    };
    ws.current.onerror = (error) => {
      console.error('WebSocket error', error);
    };
  };

  const startCamera = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((err) => {
        console.error("Error accessing the camera", err);
      });
  };

  const stopCamera = () => {
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });

    videoRef.current.srcObject = null;
  };

  const processFrame = () => {
    if (!videoRef.current || !canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    canvasRef.current.width = videoRef.current.videoWidth;
    canvasRef.current.height = videoRef.current.videoHeight;

    ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    const imageDataURL = canvasRef.current.toDataURL('image/jpeg');

    handleWebSocketSend(imageDataURL);

    setTimeout(processFrame, 100); // Process frame every 100ms (10fps)
  };

  const handleWebSocketSend = (imageSrc) => {
    const lipRGB = hexToRgb(lipColorRef.current);
    const eyeRGB = hexToRgb(eyeColorRef.current);

    const features = {
      lip_color: `${lipRGB.r},${lipRGB.g},${lipRGB.b}`,
      eye_color: eyeRGB
    };

    const message = {
      image: imageSrc.split(',')[1], // Remove the base64 header
      features: features,
    };
    ws.current.send(JSON.stringify(message));
  };

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  const handleLipColorChange = (color) => {
    setLipColor(color.hex);
    lipColorRef.current = color.hex;
    triggerWebSocketSend();
  };

  const handleEyeColorChange = (color) => {
    setEyeColor(color.hex);
    eyeColorRef.current = color.hex;
    triggerWebSocketSend();
  };

  const triggerWebSocketSend = () => {
    if (videoRef.current && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;

      ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      const imageDataURL = canvasRef.current.toDataURL('image/jpeg');

      handleWebSocketSend(imageDataURL);
    }
  };

  return (
    <div className="container">
      <h1>WebSocket Video Processor</h1>
      <div className="controls">
        <div className="color-picker">
          <label>Lip Color: </label>
          <SketchPicker
            color={lipColor}
            onChangeComplete={handleLipColorChange}
          />
        </div>
        <div className="color-picker">
          <label>Eye Color: </label>
          <SketchPicker
            color={eyeColor}
            onChangeComplete={handleEyeColorChange}
          />
        </div>
      </div>
      <div className="video-container">
        <div className="video-feed">
          <video ref={videoRef} className="video" onCanPlay={processFrame} />
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
        <div className="processed-image-container">
          {processedImage && (
            <img src={processedImage} alt="Processed" className="processed-image" />
          )}
        </div>
      </div>
    </div>
  );
};

export default WebSocketVideoProcessor;
